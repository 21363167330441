import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Container, Fab, IconButton, List, ListItem, ListItemAvatar, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router';

import { Column, Row } from '../flex';
import { PersonaIcon } from '../icons/persona-icons';
import { useStore } from '../store';

export const ListPersonas = observer(() => {
  const store = useStore();
  const navigate = useNavigate();

  return (
    <Container>
      <Column>
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          Manage your personas
        </Typography>
        <List
          sx={{
            background: (theme) => theme.palette.background.paper,
            marginTop: (theme) => theme.spacing(2),
          }}
        >
          {store.personas.collection.slice().map((persona) => {
            return (
              <ListItem
                key={persona.id}
                button
                onClick={() => store.personas.usePersona(persona.id)}
                secondaryAction={
                  <IconButton onClick={() => navigate(`/personas/${persona.id}/edit`)}>
                    <EditIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <PersonaIcon color={persona.color} />
                </ListItemAvatar>
                <Column>
                  <Row sx={{ gap: (theme) => theme.spacing(0.5) }}>
                    <Typography variant="subtitle2">
                      <strong>{persona.name}</strong>
                    </Typography>
                    <Typography variant="subtitle2">
                      <em>@{persona.handle}</em>
                    </Typography>
                  </Row>
                  <Typography variant="caption">{persona.bio}</Typography>
                </Column>
              </ListItem>
            );
          })}
        </List>
        <Row
          justifyContent="center"
          sx={{
            padding: (theme) => theme.spacing(2, 1),
          }}
        >
          <Fab
            color="primary"
            size="small"
            disabled={store.personas.collection.length >= 3}
            onClick={() => navigate('/personas/new')}
            sx={{}}
          >
            <AddIcon />
          </Fab>
        </Row>
      </Column>
    </Container>
  );
});
