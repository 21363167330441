import { User } from 'firebase/auth';
import { collection, getFirestore, onSnapshot, orderBy, query } from 'firebase/firestore';
import { action, makeObservable, observable } from 'mobx';

import { DependentDisposer } from '../disposables';
import { makePost, Post, PostDocument, PostProps } from '../models/post';
import { DomainStore } from '../store';
import { RootStore } from './root';

export class DraftStore extends DomainStore<Post, PostProps, RootStore> {
  debugName = 'DraftStore';

  draft: Post | undefined = undefined;

  private authSubs = new DependentDisposer(() => this.rootStore.auth.user);

  constructor(rootStore: RootStore) {
    super({
      rootStore,
      modelConstructor: Post,
    });
    makeObservable(this, {
      draft: observable,
      openForm: action,
      closeForm: action,
    });
  }

  async setup() {
    await super.setup();
    await this.authSubs.initialize();
    this.authSubs.addEffect('drafts', this.subToDrafts);
  }

  subToDrafts = (user: User) =>
    onSnapshot(
      query(collection(getFirestore(), 'members', user.uid, 'drafts'), orderBy('created_at', 'desc')),
      this.updateFromSnapshot,
    );

  openForm = (draft?: PostDocument) => {
    const persona_id = this.rootStore.personas.current?.id;
    if (!persona_id) {
      return;
    }
    this.draft = new Post(draft ?? makePost({ trunk_id: 'main', persona_id }));
    this.rootStore.ui.setOpened('drafts');
  };

  closeForm = () => {
    this.rootStore.ui.setClosed('drafts');
  };
}
