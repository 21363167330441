import { Button, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Column } from '../flex';
import { useStore } from '../store';

export const SignUp = observer(() => {
  const store = useStore();
  const navigate = useNavigate();

  const handleSignUp = () => {
    store.ui.workOn(async () => {
      await store.auth.signInWithGoogle();
      navigate('/main');
    });
  };

  return (
    <Column height="100%" justifyContent="center" alignItems="center">
      <Typography component="h1" variant="h4">
        Fabric
      </Typography>
      <Button disabled={store.ui.isWorking} onClick={handleSignUp}>
        Sign up with Google
      </Button>
    </Column>
  );
});
