import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';
import React from 'react';

export const Flex = React.forwardRef((props: BoxProps, ref) => {
  const { sx, ...rest } = props;
  return (
    <Box
      {...rest}
      ref={ref}
      sx={{
        display: 'flex',
        flex: 1,
        ...sx,
      }}
    />
  );
});

Flex.displayName = 'Flex';

export const Row = React.forwardRef((props: BoxProps, ref) => {
  const { sx, ...rest } = props;
  return (
    <Flex
      {...rest}
      ref={ref}
      sx={{
        flexDirection: 'row',
        flex: 'unset',
        alignItems: 'center',
        ...sx,
      }}
    />
  );
});

Row.displayName = 'Row';

export const Column = React.forwardRef((props: BoxProps, ref) => {
  const { sx, ...rest } = props;
  return (
    <Flex
      {...rest}
      ref={ref}
      sx={{
        flexDirection: 'column',
        flex: 'unset',
        ...sx,
      }}
    />
  );
});

Column.displayName = 'Column';
