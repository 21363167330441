import { CssBaseline, StyledEngineProvider, ThemeProvider, useMediaQuery } from '@mui/material';
import StylesProvider from '@mui/styles/StylesProvider';
import React, { FC, PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { StoreProvider } from './store';
import { darkTheme, lightTheme } from './theme';

export const Provider: FC<PropsWithChildren<any>> = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = prefersDarkMode ? darkTheme : lightTheme;

  return (
    <StylesProvider injectFirst>
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <StoreProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {children}
            </ThemeProvider>
          </StoreProvider>
        </BrowserRouter>
      </StyledEngineProvider>
    </StylesProvider>
  );
};
