import { Button, Typography } from '@mui/material';
import { when } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Column } from '../flex';
import { useStore } from '../store';

export const SignIn = observer(() => {
  const store = useStore();
  const navigate = useNavigate();

  const handleSignIn = () => {
    store.ui.workOn(async () => {
      await store.auth.signOut();
      await store.auth.signInWithGoogle();
      await when(() => store.members.current !== undefined);
      navigate('/');
    });
  };

  return (
    <Column height="100%" justifyContent="center" alignItems="center">
      <Column alignItems="center">
        <Typography component="h1">Fabric</Typography>
        <Button disabled={store.ui.isWorking} onClick={handleSignIn} variant="contained" color="primary">
          Sign in
        </Button>
      </Column>
    </Column>
  );
});
