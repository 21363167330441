import { User } from 'firebase/auth';
import { collection, doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { DependentDisposer } from '../disposables';
import { Member, MemberProps } from '../models';
import { Store } from '../store';
import { RootStore } from './root';

export class MemberStore extends Store<RootStore> {
  debugName = 'MemberStore';

  /**
   * The current Member model for the signed in firebase user
   */
  current?: Member = undefined;

  private authSubs = new DependentDisposer(() => this.rootStore.auth.user);

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      current: observable,
      teardown: action,
    });
  }

  async setup() {
    await super.setup();
    await this.authSubs.initialize();
    this.authSubs.addEffect('member', this.subToMember);
  }

  teardown() {
    this.current = undefined;
    super.teardown();
  }

  private subToMember = (user: User) => {
    return onSnapshot(doc(collection(getFirestore(), 'members'), user.uid), (snapshot) => {
      runInAction(() => {
        if (snapshot.exists()) {
          const member = new Member(snapshot.data() as MemberProps);
          member.id = snapshot.id;
          this.current = member;
        } else {
          this.current = undefined;
        }
      });
    });
  };
}
