import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStore } from '../store';

export const SignOut = observer(() => {
  const store = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    store.ui.workOn(async () => {
      await store.auth.signOut();
      navigate('/sign-in');
    });
  }, [navigate, store]);

  return null;
});
