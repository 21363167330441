import { collection, getFirestore, onSnapshot } from 'firebase/firestore';

import { DEFAULT_FEATURE_DOC, Feature, FeatureDocument, FeatureProps } from '../models/feature';
import { DomainStore } from '../store';
import { RootStore } from './root';

export const DEFAULT_FEATURE_DOCS: FeatureDocument[] = [
  {
    ...DEFAULT_FEATURE_DOC,
    name: 'trunks',
    settings: {
      main_id: 'main',
      can_create: false,
    },
  },
  {
    ...DEFAULT_FEATURE_DOC,
    name: 'personas',
    settings: {
      limit: 3,
    },
  },
  {
    ...DEFAULT_FEATURE_DOC,
    name: 'drafts',
    settings: {
      limit: 8,
    },
  },
];

export class FeaturesStore extends DomainStore<Feature, FeatureProps, RootStore> {
  constructor(rootStore: RootStore) {
    super({
      rootStore,
      modelConstructor: Feature,
    });
  }

  async setup() {
    await super.setup();
    this.addDisposable(this.subToFeatures, 'features');
  }

  subToFeatures = () => onSnapshot(collection(getFirestore(), 'features'), this.updateFromSnapshot);
}
