import { collection, doc, DocumentReference, getFirestore, Timestamp } from 'firebase/firestore';
import { computed, makeObservable, observable } from 'mobx';

import { Block, makeBlock } from '../block';
import { BotPrediction } from '../bot';
import { Document, makeId } from '../document';
import { makeToJS, makeUpdate, Model, PENDING_ID } from '../model';

export interface PostProps {
  trunk_id: string;
  parent_id?: string;
  persona_id: string;
  blocks: Block[];
  predictions: BotPrediction[];
}

export type PostDocument = PostProps & Document;

export const DEFAULT_POST_DOC: PostDocument = {
  id: PENDING_ID,
  created_at: Timestamp.now(),
  trunk_id: '',
  parent_id: undefined,
  persona_id: '',
  pending: false,
  blocks: [
    {
      id: PENDING_ID,
      post_id: PENDING_ID,
      type: 'text',
      properties: { text: '' },
    },
  ],
  predictions: [],
};

export function makePost(props: Omit<PostProps, 'blocks'>): PostDocument {
  const id = makeId();
  return {
    ...props,
    id,
    created_at: Timestamp.now(),
    pending: true,
    blocks: [makeBlock({ post_id: id, type: 'text', properties: { text: '' } })],
    predictions: [],
  };
}

export class Post extends Model<PostProps> implements PostDocument {
  id = DEFAULT_POST_DOC.id;
  created_at = DEFAULT_POST_DOC.created_at;
  trunk_id = DEFAULT_POST_DOC.trunk_id;
  parent_id = DEFAULT_POST_DOC.parent_id;
  persona_id = DEFAULT_POST_DOC.persona_id;
  blocks = observable.array(DEFAULT_POST_DOC.blocks);
  predictions = observable.array(DEFAULT_POST_DOC.predictions);
  pending = DEFAULT_POST_DOC.pending;

  get docRef(): DocumentReference {
    return doc(collection(getFirestore(), 'trunks', this.trunk_id, 'posts', this.id));
  }

  constructor(props: PostProps | PostDocument) {
    super();
    const { id, created_at } = props as PostDocument;
    if (id || created_at) {
      this.id = id;
      this.created_at = created_at;
    }
    this.update(props);
    makeObservable(this, {
      id: observable,
      created_at: observable,
      trunk_id: observable,
      parent_id: observable,
      blocks: observable,
      predictions: observable,
      pending: observable,
      docRef: computed,
    });
  }

  toJS = makeToJS(DEFAULT_POST_DOC, this);
  update = makeUpdate(DEFAULT_POST_DOC, this);
}
