import { Block, TextBlockProperties } from '@fabric/core/src/block';
import { TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import Markdown from 'react-markdown';

import { Column } from '../flex';

export interface TextBlockProps {
  block: Block<TextBlockProperties>;
  onChange?: (block: Block<TextBlockProperties>) => void;
  disabled?: boolean;
}

export const TextBlock = observer((props: TextBlockProps) => {
  const { block, onChange, disabled } = props;
  const [editing, setEditing] = useState(!disabled ?? onChange !== undefined);

  return editing || block.properties.text.trim() === '' ? (
    <TextField
      value={block.properties.text}
      placeholder="..."
      autoFocus
      multiline
      fullWidth
      onChange={(e) =>
        onChange &&
        onChange({
          ...block,
          properties: {
            ...block.properties,
            text: e.target.value,
          },
        })
      }
      onBlur={() => setEditing(false)}
      onFocus={() => setEditing(true)}
      sx={{
        flex: 1,
        '& *': {
          border: 'none !important',
          borderWidth: 0,
        },
        '& .MuiInputBase-root': {
          flex: 1,
          padding: (theme) => theme.spacing(0, 2),
          lineHeight: 1.5,
        },
        '& textarea': {
          // textAlign: 'center',
        },
      }}
    />
  ) : (
    <Column
      onClick={() => setEditing(true)}
      sx={{
        width: '100%',
        whiteSpace: 'pre-wrap',
        paddingTop: 0,
        paddingBottom: 0,
        padding: (theme) => theme.spacing(0, 2),
      }}
    >
      <Markdown
        components={{
          p: Typography,
        }}
      >
        {block.properties.text}
      </Markdown>
    </Column>
  );
});
