import {
  AuthError,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  User,
} from 'firebase/auth';
import { action, computed, makeObservable, observable, when } from 'mobx';

import { Store } from '../store';
import { RootStore } from './root';

export type MaybeAuthError = AuthError | undefined;

/**
 * This is a data store for authentication with firebase
 */
export class AuthStore extends Store<RootStore> {
  debugName = 'AuthStore';

  /**
   * Is there a user signed in?
   */
  get signedIn(): boolean {
    return this.user !== undefined;
  }

  /**
   * The current firebase authentication user, if present
   */
  get user(): User | undefined {
    return this._user ?? undefined;
  }

  _user?: User | null = null;
  _initStatus = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      _user: observable,
      _initStatus: observable,
      signedIn: computed,
      user: computed,
      teardown: action,
      onAuthStateChanged: action,
    });
  }

  async setup() {
    await super.setup();
    this.addDisposable(
      onAuthStateChanged(getAuth(), (user) => this.onAuthStateChanged(user)),
      'auth-state',
    );
    try {
      // There really isn't a firebase function to just get the logged in state. There is only the `onAuthStateChanged`
      // handler, and the `.currentUser` property. Our app however, is not considered to be initialized without that
      // very first authentication check, which happens on the event loop. To get around this, we flip an observable
      // flag in the auth callback and we wait for it here. This way, when the app loads we'll know for certain
      // (unless a timeout occurs) that the user is logged in or not.
      await when(() => this._initStatus, { timeout: 5000 });
    } catch (error) {
      console.debug('timed out waiting for init status', error);
    }
  }

  teardown() {
    this._user = undefined;
    this._initStatus = false;
    super.teardown();
  }

  /**
   * Sign the user in with Google auth provider
   */
  signInWithGoogle = async (): Promise<MaybeAuthError> => {
    try {
      const provider = new GoogleAuthProvider();
      const credentials = await signInWithPopup(getAuth(), provider);
      if (!credentials.user) {
        throw new Error();
      }
    } catch (error) {
      console.debug('error signing in', error);
      return error as AuthError;
    }
  };

  /**
   * Sign the user out of firebase
   */
  signOut = async (): Promise<MaybeAuthError> => {
    if (!this.signedIn) {
      return;
    }
    try {
      await signOut(getAuth());
    } catch (error) {
      console.debug('error signing out', error);
      return error as AuthError;
    }
  };

  signUpWithGoogle = async (): Promise<MaybeAuthError> => {
    try {
      const provider = new GoogleAuthProvider();
      const credentials = await signInWithPopup(getAuth(), provider);
      if (!credentials.user) {
        throw new Error();
      }
    } catch (error) {
      console.debug('error signing up', error);
      return error as AuthError;
    }
  };

  onAuthStateChanged = (user: User | null) => {
    if (!this._initStatus) {
      this._initStatus = true;
    }
    this._user = user;
  };
}
