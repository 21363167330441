import Grid4x4OutlinedIcon from '@mui/icons-material/Grid4x4Outlined';
import { AppBar as MuiAppBar, Container, Fab, IconButton, SvgIcon, Toolbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { DraftForm } from './drafts/draft-form';
import { PersonaIcon } from './icons/persona-icons';
import { useStore } from './store';

const PostFab = observer(() => {
  const store = useStore();
  const canCreate = !store.ui.opened.get('drafts') && store.personas.current !== undefined;

  return (
    <Fab
      color="primary"
      aria-label="create"
      disabled={!canCreate}
      onClick={() => store.drafts.openForm()}
      sx={{
        boxShadow: (theme) => (canCreate ? theme.shadows[8] : theme.shadows[0]),
        background: (theme) => (canCreate ? theme.palette.primary.main : 'transparent'),
        color: (theme) => (canCreate ? theme.palette.primary.contrastText : theme.palette.grey[500]),
        top: (theme) => theme.spacing(-2),
        transform: (theme) => `translateY(${canCreate ? 0 : theme.spacing(2)})`,
        transition: (theme) =>
          theme.transitions.create('all', {
            duration: 500,
          }),
        animationName: store.ui.isWorking ? 'working' : 'unset',
        animationDirection: 'alternate',
        animationDuration: '1s',
        animationIterationCount: 'infinite',

        '&.Mui-disabled': {
          backgroundColor: 'unset',
        },

        '@keyframes working': {
          from: {
            color: (theme) => (canCreate ? theme.palette.primary.contrastText : theme.palette.grey[500]),
          },
          to: {
            color: (theme) => (canCreate ? theme.palette.primary.main : 'transparent'),
          },
        },
      }}
    >
      <SvgIcon viewBox="0 0 24 24" fontSize="large">
        <path d="M7 13L11 13L11 5L13 5L13 13L17 13L17 15L13 15L13 19L11 19L11 15L7 15L7 13Z" />
      </SvgIcon>
    </Fab>
  );
});

export const AppBar = observer(() => {
  const navigate = useNavigate();

  return (
    <>
      <MuiAppBar
        sx={{
          top: 'auto',
          bottom: 0,
          position: 'fixed',
          background: (theme) => theme.palette.background.paper,
        }}
      >
        <Container sx={{ padding: 0 }}>
          <Toolbar sx={{ justifyContent: 'space-around' }}>
            <IconButton aria-label="personas" onClick={() => navigate('/personas')}>
              <PersonaIcon />
            </IconButton>
            <PostFab />
            <IconButton aria-label="threads" onClick={() => navigate('/main')}>
              <Grid4x4OutlinedIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </MuiAppBar>
      <DraftForm />
    </>
  );
});
