import { collection, documentId, getFirestore, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { computed, makeObservable } from 'mobx';

import { DependentDisposer } from '../disposables';
import { Member } from '../models';
import { Persona, PersonaProps } from '../models/persona';
import { DomainStore } from '../store';
import { RootStore } from './root';

export class PersonasStore extends DomainStore<Persona, PersonaProps, RootStore> {
  get current(): Persona | undefined {
    return this.collection.slice().find((p) => p.id === this.rootStore.members.current?.last_persona_id);
  }

  private memberSubs = new DependentDisposer(() => this.rootStore.members.current);

  constructor(rootStore: RootStore) {
    super({
      rootStore,
      modelConstructor: Persona,
    });
    makeObservable(this, {
      current: computed,
    });
  }

  async setup() {
    await super.setup();
    await this.memberSubs.initialize();
    this.memberSubs.addEffect('personas', this.subToPersonas);
  }

  usePersona = async (id: string) => {
    if (!this.rootStore.members.current) {
      return;
    }
    await updateDoc(this.rootStore.members.current.docRef, {
      last_persona_id: id,
    });
  };

  private subToPersonas = (member: Member) => {
    const personaIds = member.persona_ids?.slice() ?? [];
    if (personaIds.length > 0) {
      const personasRef = collection(getFirestore(), 'personas');
      const q = query(personasRef, where(documentId(), 'in', member.persona_ids?.slice() ?? []));
      return onSnapshot(q, this.updateFromSnapshot);
    } else {
      this.collection.clear();
      return () => ({});
    }
  };
}
