import { makeId } from './document';

export type BlockType = 'text';

export interface BlockProperties {
  [key: string]: any;
}

export interface TextBlockProperties extends BlockProperties {
  text: string;
  color?: string;
  background?: string;
}

export interface Block<P = BlockProperties> {
  type: BlockType;
  id: string;
  post_id: string;
  parent_id?: string;
  children_ids?: string[];
  properties: P;
}

export function makeBlock(props: Omit<Block, 'id'>) {
  return {
    ...props,
    id: makeId(),
  };
}
