import { Button, Container, TextField, Typography } from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Column, Row } from '../flex';
import { useStore } from '../store';

export const PersonaForm = observer(() => {
  const store = useStore();
  const navigate = useNavigate();
  const { persona_id } = useParams<{ persona_id?: string }>();
  const persona = store.personas.get(persona_id ?? '');

  const [name, setName] = useState<string>(persona?.name ?? '');
  const [handle, setHandle] = useState<string>(persona?.handle ?? '');
  const [bio, setBio] = useState<string>(persona?.bio ?? '');

  const hasChanges = name !== persona?.name || handle !== persona?.handle || bio !== persona?.bio;
  const canCommit = hasChanges && name.trim() !== '' && handle.trim() !== '';

  const onCancel = () => {
    setName(persona?.name ?? '');
    setHandle(persona?.handle ?? '');
    setBio(persona?.bio ?? '');
    navigate('/personas');
  };

  const onSave = async () => {
    if (persona) {
      await httpsCallable(
        getFunctions(),
        'personas-updatePersona',
      )({
        persona_id: persona.id,
        name,
        handle,
        bio,
      });
    } else {
      await httpsCallable(
        getFunctions(),
        'personas-createPersona',
      )({
        name,
        handle,
        bio,
      });
    }
    navigate('/personas');
  };

  return (
    <Container>
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        {persona ? 'Edit your Persona' : 'Create a new Persona'}
      </Typography>
      <Column
        sx={{
          paddingTop: (theme) => theme.spacing(2),
          gap: (theme) => theme.spacing(2),
        }}
      >
        <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} />
        <TextField label="Handle" value={handle} onChange={(e) => setHandle(e.target.value)} />
        <TextField
          label="Bio"
          placeholder="A little about you ..."
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          multiline
          minRows={4}
        />
      </Column>
      <Row
        justifyContent="center"
        sx={{
          padding: (theme) => theme.spacing(2, 1),
          gap: (theme) => theme.spacing(1),
        }}
      >
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" disabled={!canCommit} onClick={onSave}>
          Save
        </Button>
      </Row>
    </Container>
  );
});
