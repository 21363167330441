import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';

/**
 * A `Disposable` is a callable function that contains 'teardown' logic, such as unsubscribing from an event or reaction
 */
export type Disposable = () => void;

/**
 * The `Disposer` class provides functionality for reactive programming, including: registering subscriptions, async
 * initialization, and setup/teardown methods.
 */
export class Disposer {
  protected debugName = 'Disposer';

  registry = observable.map<string, Disposable>([]);
  _initialized = false;
  _initializing = false;

  get canInitalize(): boolean {
    return !this.isInitialized && !this.isInitializing;
  }

  get isInitialized(): boolean {
    return this._initialized;
  }

  get isInitializing(): boolean {
    return this._initializing;
  }

  constructor() {
    makeObservable(this, {
      registry: observable,
      _initialized: observable,
      _initializing: observable,
      isInitialized: computed,
      canInitalize: computed,
      addDisposable: action,
      disposeOf: action,
    });
  }

  /**
   * Async initialization
   */
  readonly initialize = async (): Promise<boolean> => {
    if (this._initializing || this._initialized) {
      return false;
    }
    try {
      runInAction(() => (this._initializing = true));
      await this.setup();
      runInAction(() => (this._initialized = true));
    } catch (error) {
      runInAction(() => (this._initialized = false));
      return false;
    } finally {
      runInAction(() => (this._initializing = false));
    }
    return true;
  };

  /**
   * Add a disposable function to the local registry
   */
  public addDisposable = (disposable: Disposable, key: string): string => {
    if (this.registry.has(key)) {
      this.disposeOf(key);
    }
    this.registry.set(key, disposable);
    return key;
  };

  /**
   * Dispose of a disposable in the registry
   */
  public disposeOf = (key: string): void => {
    const existing = this.registry.get(key);
    existing && existing();
    this.registry.delete(key);
  };

  /**
   * Not meant to be used outside of the `initialize` method
   */
  async setup(): Promise<void> {
    // override in extended stores
  }

  /**
   * Call `super.teardown()` at the end of the extended `teardown` method
   */
  teardown(): void {
    this.registry.forEach((disposable) => disposable());
    this.registry.clear();
    this._initializing = false;
    this._initialized = false;
  }
}

/**
 * A `Effect<T>` is a generic function that returns a disposable function.
 */
export type Effect<T> = (arg: T) => Disposable;

/**
 * A `DependentEffect<T>` represents an easily identifiable `Effect` used with `DependentDisposer`.
 */
export type DependentEffect<T> = {
  uid: string;
  effect: Effect<T>;
};

/**
 * A special `Disposer` type that has a callstack of effects to be registered whenever a given expression
 * evaluates to anything besides `undefined | null`; reducing a ton of reaction handling boilerplate. This is useful in
 * a nested store structure, where a store like `comments` would be dependent on a `post` store, when we only want to
 * be storing the comments for the currently active post. If you were to change the post, the comments would
 * automatically update as well. You must call `initialize` on instances of `DependentDisposer`s.
 */
export class DependentDisposer<T> extends Disposer {
  debugName = 'DependentDisposer';

  protected effects = observable.array<DependentEffect<T>>([]);

  protected readonly expression: () => T | undefined;
  protected expressionName?: string;

  /**
   * Although the constructor accepts an expression that returns `undefined`, the effects you register will never be
   * given `undefined` as an argument.
   */
  constructor(expression: () => T | undefined, expressionName?: string) {
    super();
    this.expression = expression;
    this.expressionName = expressionName;
  }

  async setup(): Promise<void> {
    this.addDisposable(this.reactToExpression(), 'root-disposer');
  }

  teardown(): void {
    this.effects.clear();
    super.teardown();
  }

  /**
   * Add an effect to be registered whenever its argument is defined.
   */
  addEffect = (uid: string, effect: Effect<T>): void => {
    this.effects.push({ uid, effect });
    if (this.isInitialized) {
      const current = this.expression();
      // we don't want to manually run this effect if the value is undefined
      if (current !== undefined) {
        this.addDisposable(effect(current), uid);
      }
    }
  };

  /**
   * Remove a registered effect
   */
  removeEffect = (uid: string): void => {
    const index = this.effects.findIndex((s) => s.uid === uid);
    this.effects.splice(index, 1);
    this.disposeOf(uid);
  };

  private reactToExpression = () =>
    reaction(
      this.expression as () => T,
      (arg: T) => {
        if (arg !== undefined && arg !== null) {
          this.effects.forEach(({ uid, effect }) => this.addDisposable(effect(arg), uid));
        } else {
          this.effects.forEach(({ uid }) => this.disposeOf(uid));
        }
      },
      {
        fireImmediately: true,
        name: this.expressionName,
      },
    );
}
