import { collection, doc, DocumentReference, getFirestore, Timestamp } from 'firebase/firestore';
import { computed, makeObservable, observable } from 'mobx';

import { Document } from '../document';
import { makeToJS, makeUpdate, Model, PENDING_ID } from '../model';

export interface MemberPreferences {
  developer?: boolean;
}

export interface MemberProps {
  name: string;
  preferences?: MemberPreferences;
  persona_ids?: string[];
  last_persona_id?: string;
}

export type MemberDocument = MemberProps & Document;

const DEFAULT_MEMBER_DOC: MemberDocument = {
  id: PENDING_ID,
  created_at: Timestamp.now(),
  name: '',
  preferences: undefined,
  persona_ids: [],
  last_persona_id: undefined,
};

export class Member extends Model<MemberProps> implements MemberDocument {
  id = DEFAULT_MEMBER_DOC.id;
  created_at = DEFAULT_MEMBER_DOC.created_at;
  name = DEFAULT_MEMBER_DOC.name;
  preferences = DEFAULT_MEMBER_DOC.preferences;
  persona_ids = DEFAULT_MEMBER_DOC.persona_ids;
  last_persona_id = DEFAULT_MEMBER_DOC.last_persona_id;

  get docRef(): DocumentReference {
    return doc(collection(getFirestore(), 'members'), this.id);
  }

  constructor(props: MemberProps) {
    super();
    this.update(props);
    makeObservable(this, {
      id: observable,
      created_at: observable,
      name: observable,
      preferences: observable,
      persona_ids: observable,
      docRef: computed,
    });
  }

  toJS = makeToJS(DEFAULT_MEMBER_DOC, this);
  update = makeUpdate(DEFAULT_MEMBER_DOC, this);
}
