import { EventEmitter } from 'events';
import { collection, doc, DocumentData, getFirestore, QuerySnapshot } from 'firebase/firestore';
import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';

import { Disposer } from './disposables';
import { Model } from './model';

/**
 * A special extension of `Disposer` meant to be subclassed by all stores except `RootStore`. Provides methods bound
 * to firebase authentication which automates setup/teardown of subscriptions.
 */
export class Store<T> extends Disposer {
  debugName = 'Store';

  constructor(readonly rootStore: T) {
    super();
  }

  async setup() {
    //
  }

  teardown() {
    this.unmount();
    super.teardown();
  }

  async mount() {
    //
  }

  unmount() {
    //
  }

  createId() {
    return doc(collection(getFirestore(), 'id')).id;
  }
}

type ModelConstructor<M extends Model<MP>, MP> = new (props: MP) => M;

export interface DomainStoreProps<M extends Model<MP>, MP, RS> {
  rootStore: RS;
  modelConstructor: ModelConstructor<M, MP>;
}

/**
 * The `FirebaseStore` class helps sync a collection from firestore to a local datastore. As a generic, it is given
 * the model class to be used as the collection type, the model-props type that defines required properties for
 * construction.
 */
export class DomainStore<ModelT extends Model<ModelProps>, ModelProps, RootStore> extends Store<RootStore> {
  collection: IObservableArray<ModelT>;
  modelConstructor: ModelConstructor<ModelT, ModelProps>;
  events = new EventEmitter();

  hydrated = false;
  get isHydrated() {
    return this.hydrated;
  }

  constructor({ rootStore, modelConstructor }: DomainStoreProps<ModelT, ModelProps, RootStore>) {
    super(rootStore);
    this.collection = observable.array([], {
      name: `${this.debugName}-collection`,
    });
    this.modelConstructor = modelConstructor;
    makeObservable(this, {
      collection: observable,
      hydrated: observable,
      isHydrated: computed,
      teardown: action,
      addToCollection: action,
      dehydrate: action,
      remove: action,
      updateFromSnapshot: action,
    });
  }

  teardown() {
    this.hydrated = false;
    this.events.removeAllListeners();
    super.teardown();
  }

  addToCollection = (record: ModelT) => {
    const index = this.collection.findIndex((r) => r.id === record.id);
    if (index > -1) {
      // we already have this record locally, but let's take the latest version just in case
      this.collection.splice(index, 1, record);
    } else {
      // its a new record
      this.collection.push(record);
    }
    this.events.emit('added', record.id);
  };

  dehydrate = () => {
    this.collection.clear();
    this.hydrated = false;
  };

  get = (id: string): ModelT | undefined => this.collection.find((r) => r.id === id);

  remove = (id: string) => {
    const index = this.collection.findIndex((c) => c.id === id);
    if (index > -1) {
      this.collection.splice(index, 1);
      this.events.emit('removed', id);
    }
  };

  readonly updateFromSnapshot = (snapshot: QuerySnapshot<DocumentData>) => {
    snapshot.docChanges().forEach((change) => {
      const id = change.doc.id;
      const index = this.collection.findIndex((d) => d.id === id);
      if (change.type === 'removed') {
        this.collection.splice(index, 1);
        this.events.emit('removed', id);
        return;
      }
      const props = change.doc.data() as ModelProps;
      if (change.type === 'modified') {
        if (index > -1) {
          this.collection[index].update({ id, ...props });
          this.events.emit('modified', id);
        } else {
          // somehow, we don't have this record locally already. create a new one
          console.warn('Expected to have record available locally...');
          const record = new this.modelConstructor({ id, ...props });
          this.collection.splice(index, 1, record);
          this.events.emit('added', id);
        }
      } else if (change.type === 'added') {
        if (index > -1) {
          // we already have this record locally, but let's take the latest version just in case
          const record = new this.modelConstructor({ id, ...props });
          this.collection.splice(index, 1, record);
        } else {
          // its a new record
          this.collection.push(new this.modelConstructor({ id, ...props }));
        }
        this.events.emit('added', id);
      }
    });
    if (!this.hydrated) {
      this.hydrated = true;
    }
  };
}
