import { collection, doc, DocumentReference, getFirestore, Timestamp } from 'firebase/firestore';
import { computed, makeObservable, observable } from 'mobx';

import { Document } from '../document';
import { makeToJS, makeUpdate, Model, PENDING_ID } from '../model';

export interface FeatureProps {
  name: string;
  enabled: boolean;
  settings?: Record<string, string | number | boolean | undefined>;
}

export type FeatureDocument = FeatureProps & Document;

export const DEFAULT_FEATURE_DOC: FeatureDocument = {
  id: PENDING_ID,
  created_at: Timestamp.now(),
  name: '',
  enabled: false,
  settings: undefined,
};

export class Feature extends Model<FeatureProps> implements FeatureDocument {
  id = DEFAULT_FEATURE_DOC.id;
  created_at = DEFAULT_FEATURE_DOC.created_at;
  name = DEFAULT_FEATURE_DOC.name;
  enabled = DEFAULT_FEATURE_DOC.enabled;
  settings = DEFAULT_FEATURE_DOC.settings;

  get docRef(): DocumentReference {
    return doc(collection(getFirestore(), 'features', this.id));
  }

  constructor(props: FeatureProps) {
    super();
    this.update(props);
    makeObservable(this, {
      id: observable,
      created_at: observable,
      name: observable,
      enabled: observable,
      settings: observable,
      docRef: computed,
    });
  }

  toJS = makeToJS(DEFAULT_FEATURE_DOC, this);
  update = makeUpdate(DEFAULT_FEATURE_DOC, this);
}
