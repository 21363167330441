import { Timestamp } from 'firebase/firestore';
import ShortUniqueId from 'short-unique-id';

const uid = new ShortUniqueId({ length: 16 });

export function makeId(): string {
  return uid();
}

export interface Document {
  id: string;
  created_at: Timestamp;
  pending?: boolean;
}
