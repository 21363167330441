import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

import { Root, RootStore } from '@fabric/core/src/stores/root';
import { getApps, initializeApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';
import { observer } from 'mobx-react';
import React, { createContext, useContext, useEffect } from 'react';

const storeContext = createContext<RootStore | null>(null);
const store = new Root();

if (getApps().length === 0) {
  const app = initializeApp({
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
  });
  initializeFirestore(app, {
    ignoreUndefinedProperties: true,
  });
}

if (process.env.NODE_ENV === 'development') {
  (window as any).store = store;
}

export const useStore = () => {
  const root = useContext(storeContext);
  if (!root) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return root;
};

export const StoreProvider = observer(({ children }: { children?: any }) => {
  useEffect(() => {
    store.initialize();
    return () => store.teardown();
  }, []);

  return store.isInitialized ? <storeContext.Provider value={store}>{children}</storeContext.Provider> : null;
});
