import { Block, TextBlockProperties } from '@fabric/core/src/block';
import { BotPrediction } from '@fabric/core/src/bot';
import { BOT_LABELS } from '@fabric/core/src/bots/mediator';
import { Button, Collapse, Container, Link, SwipeableDrawer, Toolbar, Tooltip, Typography } from '@mui/material';
import { serverTimestamp, Timestamp } from 'firebase/firestore';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { TextBlock } from '../blocks/text-block';
import { Column, Flex, Row } from '../flex';
import { useStore } from '../store';

export const DraftForm = observer(() => {
  const store = useStore();
  const draft = store.drafts.draft;
  const [blocks, setBlocks] = useState<Block[]>(draft?.blocks ?? []);
  const [predictions, setPredictions] = useState<BotPrediction[]>(draft?.predictions?.filter((l) => l.match) ?? []);
  const [focusedPrediction, setFocusedPrediction] = useState<BotPrediction | undefined>();

  const onSubmit = async () => {
    await store.ui.workOn(async () => {
      if (!draft || !store.mediator) {
        return;
      }
      if (draft.pending) {
        const mediation = await store.mediator.createDraft({
          ...draft.toJS(),
          id: draft.id,
          created_at: serverTimestamp() as Timestamp,
          blocks,
        });
        const failed = mediation.filter((p) => p.match);
        setPredictions(failed.length ? failed : []);
        if (!failed.length) {
          store.drafts.closeForm();
        }
      } else {
        const mediation = await store.mediator.updateDraft(draft.id, {
          ...draft.toJS(),
          blocks,
        });
        const failed = mediation.filter((p) => p.match);
        setPredictions(failed.length ? failed : []);
      }
      setFocusedPrediction(undefined);
    });
  };

  const onDelete = async () => {
    if (!confirm('This action cannot be undone, you will lose this draft forever.')) {
      return;
    }
    await store.ui.workOn(async () => {
      if (draft && store.mediator) {
        await store.mediator.deleteDraft(draft.id);
        store.drafts.closeForm();
      }
    });
  };

  useEffect(() => {
    if (draft) {
      setBlocks(draft.blocks);
      setPredictions(draft.predictions?.filter((l) => l.match));
      setFocusedPrediction(undefined);
    }
  }, [draft]);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={store.ui.opened.get('drafts') ?? false}
      onOpen={() => store.drafts.openForm()}
      onClose={store.drafts.closeForm}
      elevation={1}
      sx={{
        zIndex: (theme) => theme.zIndex.appBar - 1,
      }}
    >
      <Container>
        {predictions.length > 0 && (
          <Row
            sx={{
              padding: (theme) => theme.spacing(0, 2, 1, 2),
              gap: (theme) => theme.spacing(1),
              flexWrap: 'wrap',
            }}
          >
            {predictions.map((prediction, i) => {
              return (
                <Tooltip key={`${prediction.label}-${i}`} placement="top" title={prediction.p.toFixed(2)}>
                  <Button
                    key={`${prediction.label}-${i}`}
                    variant={prediction.label === focusedPrediction?.label ? 'contained' : 'outlined'}
                    color="secondary"
                    onClick={() =>
                      prediction.label !== focusedPrediction?.label
                        ? setFocusedPrediction(prediction)
                        : setFocusedPrediction(undefined)
                    }
                  >
                    {prediction.label}
                  </Button>
                </Tooltip>
              );
            })}
          </Row>
        )}
        {focusedPrediction && (
          <Column
            sx={{
              padding: (theme) => theme.spacing(0, 2, 1, 2),
            }}
          >
            <Collapse in appear>
              <Column>
                <Typography variant="body2" fontStyle="italic" textAlign="right" gutterBottom>
                  {focusedPrediction && BOT_LABELS[focusedPrediction.label].mediation}
                </Typography>
                <Typography variant="caption" gutterBottom>
                  {focusedPrediction && BOT_LABELS[focusedPrediction.label].description}
                </Typography>
              </Column>
            </Collapse>
          </Column>
        )}
        <Column>
          {blocks.map((block, i) => {
            switch (block.type) {
              case 'text': {
                return (
                  <TextBlock
                    key={`${block.id}-${i}`}
                    block={block as Block<TextBlockProperties>}
                    disabled={store.ui.isWorking}
                    onChange={(changed) => {
                      const next = blocks.slice();
                      next.splice(i, 1, changed);
                      setBlocks(next);
                    }}
                  />
                );
              }
            }
          })}
        </Column>

        <Row
          sx={{
            marginTop: (theme) => theme.spacing(1),
            padding: (theme) => theme.spacing(1, 2, 0, 2),
            gap: (theme) => theme.spacing(1),
          }}
        >
          <Link component={ReactRouterLink} to="/drafts" variant="button" onClick={store.drafts.closeForm}>
            All drafts
          </Link>
          <Flex />
          {!draft?.pending && (
            <Button variant="outlined" color="secondary" onClick={onDelete}>
              Delete
            </Button>
          )}
          <Button variant="contained" onClick={onSubmit}>
            {draft?.pending ? `Create draft` : `Save draft`}
          </Button>
        </Row>
      </Container>
      <Toolbar />
    </SwipeableDrawer>
  );
});
