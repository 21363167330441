import * as toxicity from '@tensorflow-models/toxicity';
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  getFirestore,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';

import { Bot, BotLabelInfo, BotPrediction } from '../bot';
import { Disposer } from '../disposables';
import { PostDocument, PostProps } from '../models/post';

export const BOT_LABELS: Record<string, BotLabelInfo> = {
  //
  // toxicity model
  // - descriptions from: https://github.com/conversationai/conversationai.github.io/blob/main/crowdsourcing_annotation_schemes/toxicity_with_subattributes.md
  toxicity: {
    description: `A rude, disrespectful, unreasonable comment or otherwise somewhat likely to make a user leave a discussion or give up on sharing their perspective.`,
    mediation: `Let's try to keep things civil.`,
    links: {
      src: 'https://github.com/tensorflow/tfjs-models/tree/master/toxicity',
      docs: 'https://github.com/conversationai/conversationai.github.io/blob/main/crowdsourcing_annotation_schemes/toxicity_with_subattributes.md',
    },
  },
  severe_toxicity: {
    description: `A very hateful, aggressive, disrespectful comment or otherwise very likely to make a user leave a discussion or give up on sharing their perspective.`,
    mediation: '',
    links: {
      src: 'https://github.com/tensorflow/tfjs-models/tree/master/toxicity',
      docs: 'https://github.com/conversationai/conversationai.github.io/blob/main/crowdsourcing_annotation_schemes/toxicity_with_subattributes.md',
    },
  },
  threat: {
    description: `Describes a wish or intention for pain, injury, or violence against an individual or group.`,
    mediation: '',
    links: {
      src: 'https://github.com/tensorflow/tfjs-models/tree/master/toxicity',
      docs: 'https://github.com/conversationai/conversationai.github.io/blob/main/crowdsourcing_annotation_schemes/toxicity_with_subattributes.md',
    },
  },
  insult: {
    description: `Insulting, inflammatory, or negative comment towards a person or a group of people.`,
    mediation: '',
    links: {
      src: 'https://github.com/tensorflow/tfjs-models/tree/master/toxicity',
      docs: 'https://github.com/conversationai/conversationai.github.io/blob/main/crowdsourcing_annotation_schemes/toxicity_with_subattributes.md',
    },
  },
  identity_attack: {
    description: `A negative, discriminatory or hateful comment about a person or group of people based on criteria including (but not limited to) race or ethnicity, religion, gender, nationality or citizenship, disability, age or sexual orientation.`,
    mediation: '',
    links: {
      src: 'https://github.com/tensorflow/tfjs-models/tree/master/toxicity',
      docs: 'https://github.com/conversationai/conversationai.github.io/blob/main/crowdsourcing_annotation_schemes/toxicity_with_subattributes.md',
    },
  },
  obscene: {
    description: `Contains swear words, curse words, or other obscene or profane language.`,
    mediation: '',
    links: {
      src: 'https://github.com/tensorflow/tfjs-models/tree/master/toxicity',
      docs: 'https://github.com/conversationai/conversationai.github.io/blob/main/crowdsourcing_annotation_schemes/toxicity_with_subattributes.md',
    },
  },
  sexual_explicit: {
    description: `Contains references to sexual acts or body parts sexual way, or other lewd content.`,
    mediation: `A little too horny for main, don't you think?`,
    links: {
      src: 'https://github.com/tensorflow/tfjs-models/tree/master/toxicity',
      docs: 'https://github.com/conversationai/conversationai.github.io/blob/main/crowdsourcing_annotation_schemes/toxicity_with_subattributes.md',
    },
  },
};

export interface MediatorOptions {
  /**
   * Mediators are scoped to members and have access to all personas.
   */
  member_id: string;
}

/**
 * Mediators exist on the user's device to mediate messages from other users
 * and to run the first level of content moderation, with eventual submission
 * to the moderator queue if successful.
 *
 * Syncs remote assets to device.
 *
 * Models with tensoflow.js in the browser:
 * - USE https://github.com/tensorflow/tfjs-models/tree/master/universal-sentence-encoder
 * - Toxicity https://github.com/tensorflow/tfjs-models/tree/master/toxicity
 * - Cosine Similarity https://github.com/compute-io/cosine-similarity
 */
export class Mediator extends Disposer implements Bot {
  readonly type = 'mediator';
  readonly readCollection: string = 'bots/mediators/inbound';
  readonly options: MediatorOptions;

  private toxicityModel: toxicity.ToxicityClassifier | undefined = undefined;

  get firestore(): Firestore {
    return getFirestore();
  }

  constructor(options: MediatorOptions) {
    super();
    this.options = options;
  }

  async setup(): Promise<void> {
    await super.setup();
    this.addDisposable(this.subToInboundCollection(), 'sub-to-inbound-collection');
  }

  teardown(): void {
    super.teardown();
    this.toxicityModel = undefined;
  }

  private subToInboundCollection = () =>
    onSnapshot(
      collection(this.firestore, 'members', this.options.member_id, this.readCollection), //
      (snapshot) => {
        console.log(`/members/${this.options.member_id}/${this.readCollection}`, snapshot);
      },
    );

  createDraft = async (draft: PostDocument): Promise<BotPrediction[]> => {
    const [, predictions] = await this.approveDraft(draft);
    const ref = doc(collection(this.firestore, 'members', this.options.member_id, 'drafts'), draft.id);
    await setDoc(ref, { ...draft, predictions, pending: false, created_at: serverTimestamp() });
    return predictions;
  };

  updateDraft = async (id: string, props: PostProps): Promise<BotPrediction[]> => {
    const [, predictions] = await this.approveDraft(props);
    const draftDoc = doc(collection(this.firestore, 'members', this.options.member_id, 'drafts'), id);
    await updateDoc(draftDoc, { ...props, predictions });
    return predictions;
  };

  deleteDraft = async (id: string) => {
    const draftDoc = doc(collection(this.firestore, 'members', this.options.member_id, 'drafts'), id);
    await deleteDoc(draftDoc);
  };

  approveDraft = async (draft: PostProps): Promise<[boolean, BotPrediction[]]> => {
    let predictions: BotPrediction[] = [];
    const blocks = draft.blocks ?? [];

    if (!blocks.length) {
      return [false, predictions];
    }

    const persona = await getDoc(doc(collection(this.firestore, 'personas'), draft.persona_id));
    const trust = persona.get('trust') ?? 0.5;

    if (!this.toxicityModel) {
      console.log('loading model...');
      const labels =
        trust < 0.75
          ? ['identity_attack', 'severe_toxicity', 'threat', 'insult', 'obscene', 'sexual_explicit', 'toxicity']
          : ['identity_attack', 'severe_toxicity', 'threat'];
      this.toxicityModel = await toxicity.load(trust, labels);
      console.log('loaded!');
    }
    const blockTexts = blocks
      .filter((block) => block.type === 'text')
      .map((block) => block.properties?.text ?? '')
      .filter((t) => t && t.trim() !== '');

    // Process toxicity
    try {
      const classifications = await this.toxicityModel.classify(blockTexts);
      const failed = classifications.filter(
        (prediction) => prediction.results.filter((result) => result.match).length > 0,
      );
      predictions = [
        ...predictions,
        ...classifications.map(
          (c) =>
            ({
              label: c.label,
              match: c.results.map((r) => r.match).filter(Boolean).length > 0,
              p: c.results
                // .map((r) => Math.max(r.probabilities[0], r.probabilities[1]))
                .map((r) => r.probabilities[1])
                .reduce((previous, current) => (current > previous ? current : previous), 0),
            } as BotPrediction),
        ),
      ];
      if (failed.length) {
        return [false, predictions];
      }
    } catch (e) {
      console.error(e);
    }

    console.log('ok');
    return [true, predictions];
  };

  submitDraft = async (id: string) => {
    const outboundRef = doc(collection(this.firestore, 'members', this.options.member_id, 'outbound'), id);
    await setDoc(outboundRef, { submitted_at: serverTimestamp() });
  };
}
