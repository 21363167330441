import { collection, doc, DocumentReference, getFirestore, Timestamp } from 'firebase/firestore';
import { computed, makeObservable, observable } from 'mobx';

import { Document } from '../document';
import { makeToJS, makeUpdate, Model, PENDING_ID } from '../model';

export interface PersonaProps {
  name: string;
  handle: string;
  bio: string;
  color: string;
  thread_ids: string[];
}

export interface PersonaBotProps {
  trust: number;
}

export type PersonaDocument = PersonaProps & PersonaBotProps & Document;

export const DEFAULT_PERSONA_DOC: PersonaDocument = {
  id: PENDING_ID,
  created_at: Timestamp.now(),
  name: '',
  handle: '',
  bio: '',
  color: '#000000',
  thread_ids: [],
  // bot props
  trust: 0.5,
};

export class Persona extends Model<PersonaProps> implements PersonaDocument {
  id = DEFAULT_PERSONA_DOC.id;
  created_at = DEFAULT_PERSONA_DOC.created_at;
  name = DEFAULT_PERSONA_DOC.name;
  handle = DEFAULT_PERSONA_DOC.handle;
  bio = DEFAULT_PERSONA_DOC.bio;
  color = DEFAULT_PERSONA_DOC.color;
  thread_ids = DEFAULT_PERSONA_DOC.thread_ids;

  trust = DEFAULT_PERSONA_DOC.trust;

  get docRef(): DocumentReference {
    return doc(collection(getFirestore(), 'personas', this.id));
  }

  constructor(props: PersonaProps) {
    super();
    this.update(props);
    makeObservable(this, {
      id: observable,
      created_at: observable,
      name: observable,
      handle: observable,
      bio: observable,
      color: observable,
      thread_ids: observable,
      docRef: computed,
    });
  }

  toJS = makeToJS(DEFAULT_PERSONA_DOC, this);
  update = makeUpdate(DEFAULT_PERSONA_DOC, this);
}
