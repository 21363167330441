import { reaction } from 'mobx';

import { Mediator } from '../bots/mediator';
import { Disposer } from '../disposables';
import { AuthStore } from './auth';
import { DraftStore } from './drafts';
import { FeaturesStore } from './features';
import { MemberStore } from './members';
import { PersonasStore } from './personas';
import { UIStore } from './ui';

export interface RootStore {
  auth: AuthStore;
  features: FeaturesStore;
  drafts: DraftStore;
  members: MemberStore;
  personas: PersonasStore;
  ui: UIStore;
  mediator?: Mediator;
}

export class Root extends Disposer implements RootStore {
  mediator?: Mediator = undefined;

  readonly auth: AuthStore;
  readonly features: FeaturesStore;
  readonly drafts: DraftStore;
  readonly members: MemberStore;
  readonly personas: PersonasStore;
  readonly ui: UIStore;

  constructor() {
    super();
    this.auth = new AuthStore(this);
    this.features = new FeaturesStore(this);
    this.drafts = new DraftStore(this);
    this.members = new MemberStore(this);
    this.personas = new PersonasStore(this);
    this.ui = new UIStore(this);
  }

  getToken = async () => {
    const token = await this.auth.user?.getIdToken();
    const userId = this.auth.user?.uid;

    if (!token || !userId) {
      throw new Error('Expected to be logged in');
    }

    return { token, userId };
  };

  async setup() {
    await super.setup();
    await this.auth.initialize();
    await this.ui.initialize();
    await this.members.initialize();
    await this.personas.initialize();
    await this.drafts.initialize();
    await this.features.initialize();
    this.addDisposable(this.reactToAuthUser(), 'react-to-auth-user');
  }

  teardown() {
    this.features.teardown();
    this.drafts.teardown();
    this.personas.teardown();
    this.members.teardown();
    this.ui.teardown();
    this.auth.teardown();
    super.teardown();
  }

  private reactToAuthUser = () =>
    reaction(
      () => this.auth.user,
      async (user) => {
        if (user) {
          this.members.canInitalize && (await this.members.initialize());
          this.mediator = new Mediator({ member_id: user.uid });
          await this.mediator.initialize();
        } else {
          this.members.teardown();
          this.mediator?.teardown();
        }
      },
      {
        name: 'setup-teardown-auth-dependent-stores',
        fireImmediately: true,
      },
    );
}
