import { Block, TextBlockProperties } from '@fabric/core/src/block';
import { Container, List, ListItem, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { TextBlock } from '../blocks/text-block';
import { useStore } from '../store';

export const ListDrafts = observer(() => {
  const store = useStore();
  const drafts = store.drafts.collection.slice();

  return (
    <Container>
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        All drafts
      </Typography>
      <List
        sx={{
          background: (theme) => theme.palette.background.paper,
          marginTop: (theme) => theme.spacing(2),
        }}
      >
        {drafts.map((draft, i) => {
          return (
            <ListItem
              key={draft.id}
              button
              onClick={() => store.drafts.openForm(draft)}
              divider={i !== drafts.length - 1}
            >
              {draft.blocks.map((block, i) => {
                switch (block.type) {
                  case 'text': {
                    return <TextBlock key={`${block.id}-${i}`} disabled block={block as Block<TextBlockProperties>} />;
                  }
                }
              })}
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
});
