import { observer } from 'mobx-react';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AppBar } from './app-bar';
import { SignIn } from './auth/sign-in';
import { SignOut } from './auth/sign-out';
import { SignUp } from './auth/sign-up';
import { ListDrafts } from './drafts/list-drafts';
import { ListPersonas } from './personas/list-personas';
import { PersonaForm } from './personas/persona-form';
import { ShowPersona } from './personas/show-persona';
import { useStore } from './store';

export const AppRoutes = observer(() => {
  const store = useStore();

  if (!store.auth.isInitialized) {
    return null;
  }

  if (!store.auth.user) {
    return (
      <Routes>
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="*" element={<Navigate to="/sign-up" />} />
      </Routes>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/trunks/:trunk_id/:post_id" />
        <Route path="/trunks/:trunk_id" />
        <Route path="/trunks" />

        <Route path="/personas/new" element={<PersonaForm />} />
        <Route path="/personas/:persona_id/edit" element={<PersonaForm />} />
        <Route path="/personas/:persona_id" element={<ShowPersona />} />
        <Route path="/personas" element={<ListPersonas />} />

        <Route path="/drafts" element={<ListDrafts />} />

        <Route path="/account" />
        <Route path="/sign-out" element={<SignOut />} />

        <Route path="*" element={<Navigate replace to="/trunks/main" />} />

        {/* <Route path="/threads/new" element={<ShowThread />} />
        <Route path="/threads/:thread_id/edit" element={<ThreadForm />} />
        <Route path="/threads/:thread_id/:post_id" element={<ThreadForm />} />
        <Route path="/threads/:thread_id" element={<ShowThread />} />
        <Route path="/threads" element={<ListThreads />} />

        <Route path="*" element={<Navigate replace to="/threads" />} /> */}
      </Routes>
      <AppBar />
    </>
  );
});
