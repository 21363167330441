import { grey } from '@mui/material/colors';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import chroma from 'chroma-js';
import React from 'react';

export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
export const DARK_PAPER = '#101010';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    example: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    example?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    example: true;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const commonTheme: ThemeOptions = {
  spacing: 10,

  shape: {
    borderRadius: 0,
  },

  typography: {
    fontFamily: `sans-serif`,
    h1: {
      fontSize: '30px',
      fontWeight: 700,
    },
    button: {
      textTransform: 'none',
    },
    caption: {
      lineHeight: '1',
    },

    example: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '30px',
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
          width: '100%',
        },
        '& #root': {
          height: '100%',
          width: '100%',

          '& ::-moz-selection': {
            background: grey[500],
          },
          '& ::selection': {
            background: grey[500],
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        sizeSmall: {
          borderRadius: 7,
        },
        sizeLarge: {
          borderRadius: 0,
          padding: '10px',
          minWidth: 'unset',
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'sm',
      },
      styleOverrides: {
        maxWidthSm: {
          padding: '20px 0',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiDivider: {
      defaultProps: {
        variant: 'fullWidth',
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {},
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        sizeSmall: {},
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
  },
};

export const darkTheme = createTheme({
  ...commonTheme,
  palette: {
    mode: 'dark',
    background: {
      default: grey[900],
      paper: DARK_PAPER,
    },
    primary: {
      main: grey[50],
      contrastText: BLACK,
    },
    secondary: {
      main: grey[400],
      contrastText: BLACK,
    },
    text: {
      primary: WHITE,
      secondary: WHITE,
    },
  },

  components: {
    ...commonTheme.components,
    MuiBackdrop: {
      ...commonTheme.components?.MuiBackdrop,
      styleOverrides: {
        root: {
          background: chroma.hex(DARK_PAPER).alpha(0.8).css(),
        },
      },
    },
  },
});

export const lightTheme = createTheme({
  ...commonTheme,
  palette: {
    mode: 'light',
    background: {
      default: grey[100],
      paper: WHITE,
    },
    primary: {
      main: grey[900],
      contrastText: WHITE,
    },
    secondary: {
      main: grey[700],
      contrastText: WHITE,
    },
    text: {
      primary: BLACK,
      secondary: BLACK,
    },
  },

  components: {
    ...commonTheme.components,
    MuiBackdrop: {
      ...commonTheme.components?.MuiBackdrop,
      styleOverrides: {
        root: {
          background: chroma.hex(WHITE).alpha(0.8).css(),
        },
      },
    },
    MuiTextField: {
      ...commonTheme.components?.MuiTextField,
      styleOverrides: {
        root: {
          background: WHITE,
        },
      },
    },
  },
});
