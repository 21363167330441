import { action, computed, makeObservable, observable } from 'mobx';

import { Store } from '../store';
import { RootStore } from './root';

export class UIStore extends Store<RootStore> {
  debugName = 'UIStore';

  workTasks = observable.map<string, boolean>([]);
  opened = observable.map<string, boolean>([]);

  get isWorking() {
    return Array.from(this.workTasks.values()).filter((v) => v).length > 0;
  }

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      workTasks: observable,
      opened: observable,
      isWorking: computed,
      startWork: action,
      stopWork: action,
      workOn: action,
      setOpened: action,
      setClosed: action,
    });
  }

  async setup() {
    await super.setup();
  }

  teardown() {
    super.teardown();
  }

  getTasks = () => Array.from(this.workTasks.keys());

  startWork = (uid: string) => {
    this.workTasks.set(uid, true);
    return uid;
  };

  stopWork = (uid: string) => {
    this.workTasks.delete(uid);
  };

  workOn = async (func: () => Promise<any>, workId?: string) => {
    workId = this.startWork(workId ?? this.createId());
    try {
      return await func();
    } finally {
      this.stopWork(workId);
    }
  };

  setOpened = (key: string) => this.opened.set(key, true);
  setClosed = (key: string) => this.opened.set(key, false);
}
